import TotoImg from "./assets/toto-img.jpg"
import ManuImg from "./assets/manu-img.png"
import AgusImg from "./assets/agus-img.png"
import TinchoImg from "./assets/tincho-img.jpeg"

export const teamMembers = [
  {
    name: "Manuel Casella",
    img: ManuImg,
    description: "Co-Founder Animaland"
  },
  {
    name: "Agus Piastrellini",
    img: AgusImg,
    description: "Co-Founder Animaland"
  },
  {
    name: "Tomás Ruffa",
    img: TotoImg,
    description: "Co-Founder Animaland"
  },
  {
    name: "Martin Jusbicher",
    img: TinchoImg,
    description: "Front-end Engineer"
  },
]