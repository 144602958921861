import React from "react";
import "./Footer.scss";
import FacebookLogo from "../../assets/facebook-logo.svg";
import InstagramLogo from "../../assets/instagram-logo.svg";
import LinkedinLogo from "../../assets/linkedin-logo.svg";
import Line from "../../assets/line.svg";

const Footer = () => {
  return (
    <>
      <img className="line-svg" src={Line} alt="" />
      <div className="footer">
        <div className="footer__logos__container"> 
        <a className="footer__logos" href="https://facebook.com" target="_blank"><img src={FacebookLogo} alt="" /></a>
        <a className="footer__logos" href="https://instagram.com" target="_blank"><img src={InstagramLogo} alt="" /></a>
        <a className="footer__logos" href="https://linkedin.com" target="_blank"><img src={LinkedinLogo} alt="" /></a>
        </div>
      </div>
      <div className="footer__container">
        <span className="footer__container__text">
          © 2023 Animaland. Todos los derechos reservados.
        </span>
      </div>
    </>
  );
};

export default Footer;
