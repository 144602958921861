import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import Main from "./components/Main/Main";
import OurApp from "./components/OurApp/OurApp";
import OurTeam from "./components/OurTeam/OurTeam";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="container__principal">
        <Main />
        <OurApp />
        <OurTeam />
        <Footer />
      </div>
    </div>
  );
}

export default App;
