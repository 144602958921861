import React from "react";
import "./Navbar.scss";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { Link } from "react-scroll";

const Navbar = () => {
  return (
    <div className="header">
      <div className="header__container">
        <Link to="home" spy={true} smooth={true} offset={-100} duration={500}>
          <span className="header__title">Animaland</span>
        </Link>
        <BurgerMenu />
      </div>
    </div>
  );
};

export default Navbar;
