import React from "react";
import "./Main.scss";
import homeImg from "../../assets/app-img.png";
import googlePlayImg from "../../assets/google-play.webp";
import appStoreImg from "../../assets/app-store.webp";

const Main = () => {
  return (
    <div className="main-container" id="home">
      <div className="main-container__content">
        <span className="main-container__title">Animaland</span>
        <span className="main-container__text">
          Descubrí un nuevo mundo <br /> pet-friendly. Informate a través
          del mapa interactivo, gestioná a tu mascota desde su propio perfil,
          activá recordatorios importantes y sé parte de comunidades para
          dueños responsables.
        </span>
        <div className="download-container__img-container__desktop">
          <img
            className="download-container__img-google"
            src={googlePlayImg}
            alt=""
          />
          <img
            className="download-container__img-apple"
            src={appStoreImg}
            alt=""
          />
        </div>
      </div>
      <div className="download-container">
        <img className="download-container__img" src={homeImg} alt="" />
        <div className="download-container__img-container">
          <img
            className="download-container__img-google"
            src={googlePlayImg}
            alt=""
          />
          <img
            className="download-container__img-apple"
            src={appStoreImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
