import React from "react";
import "./OurApp.scss";
import PawIcon from "../../assets/paw-icon.png";
import HomeIcon from "../../assets/home-icon.png";
import DoctorIcon from "../../assets/doctor-icon.png";
import PawsBackground from "../../assets/paws-background.png";

const OurApp = () => {
  return (
    <div className="our-app__container" id="ourApp">
      <img
        className="our-app__container__background-img"
        src={PawsBackground}
        alt=""
      />
      <div className="our-app__card">
        <span className="our-app__title">La aplicación</span>
        <div className="our-app__content">
          <div className="our-app__content__first">
            <img className="our-app__content__icon" src={PawIcon} alt="" />
            <div className="our-app__content__text">
              <span className="our-app__content__title">
                Perfil de Mascotas
              </span>
              <span className="our-app__content__description">
                Gestioná el día a día de tu mascota. Varias personas pueden
                estar asociadas a un mismo perfil para acceder al calendario de
                vacunas y recordatorios.
              </span>
            </div>
          </div>
          <div className="our-app__content__first">
            <img className="our-app__content__icon" src={DoctorIcon} alt="" />
            <div className="our-app__content__text">
              <span className="our-app__content__title">Mapa Interactivo</span>
              <span className="our-app__content__description">
                Información detallada de veterinarias, refugios, lugares
                pet-friendly, hospitales públicos para mascotas y guarderías,
                entre otros.
              </span>
            </div>
          </div>
          <div className="our-app__content__first">
            <img className="our-app__content__icon" src={HomeIcon} alt="" />
            <div className="our-app__content__text">
              <span className="our-app__content__title">Comunidades</span>
              <span className="our-app__content__description">
                Podes vincularte fácilmente con otros dueños. Asistí a
                encuentros pet-friendly y a eventos varios con tu mascota.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurApp;
