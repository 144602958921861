import React, { useEffect, useState } from "react";
import "./BurgerMenu.scss";
import { Link } from "react-scroll";

const Navbar = () => {
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [menu_none, setMenuNone] = useState("menu-none");
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [hasHamburguer, setHasHamburguer] = useState(width > 768 ? false : true);

  const updateMenu = () => {
    if(!!hasHamburguer) {
    const myElement = document.getElementById("body");
      if (!isMenuClicked) {
        myElement.style.overflow = "hidden";
        setBurgerClass("burger-bar clicked");
        setMenuClass("menu visible");
        setMenuNone("menu-active")
      } else {
        myElement.style.overflow = "visible";
        setBurgerClass("burger-bar unclicked");
        setMenuClass("menu hidden");
        setMenuNone("menu-none")
      }
      setIsMenuClicked(!isMenuClicked);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setMenuClass("menu hidden");
      setMenuNone("menu-hidden");
      if(window.innerWidth > 768) {
        setHasHamburguer(false)
      } else {
        setHasHamburguer(true)
        setIsMenuClicked(false);
      }
    }

    window.addEventListener('resize', handleResize)
  })

  return (
    <>
      <nav>
        <div className="burger-menu" onClick={updateMenu}>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
          <div className={burger_class}></div>
        </div>
      </nav>

      <div className={menu_class}>
        <div className={menu_none}>
          <Link
            onClick={updateMenu}
            to="home"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="menu-sections"
          >
            HOME
          </Link>
          <Link
            onClick={updateMenu}
            to="ourApp"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            QUE HACEMOS
          </Link>
          <Link
            onClick={updateMenu}
            to="ourTeam"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            NOSOTROS
          </Link>
          <Link
            onClick={updateMenu}
            to="sixthContainer"
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}
            className="menu-sections"
          >
            CONTACTO
          </Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;