import React, { useEffect, useState, useRef } from "react";
import "./OurTeam.scss";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { teamMembers } from "../../utils";

const OurTeam = () => {
  const [counter, setCounter] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const membersElement = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
      setCounter(0);
    }

    window.addEventListener('resize', handleResize)
  })

  const nextSlide = () => {
    if (width > 768) {
      const containerWidth = membersElement?.current?.offsetWidth / 320
      const canUse = containerWidth < teamMembers.length
      if (canUse) {
        if ((teamMembers.length * 320) - (membersElement?.current?.offsetWidth - counter) > 0) {
          setCounter(counter - 320);
        }
      }
    } else {
      const containerWidth = membersElement?.current?.offsetWidth / 250
      const canUse = containerWidth < teamMembers.length
      if (canUse) {
        if ((teamMembers.length * 250) - (membersElement?.current?.offsetWidth - counter) > 0) {
          setCounter(counter - 250);
        }
      }
    }
  }

  const previousSlide = () => {
    if (width > 768) {
      const containerWidth = membersElement?.current?.offsetWidth / 320
      const canUse = containerWidth < teamMembers.length
      if (canUse && counter < 0) {
        if ((membersElement?.current?.offsetWidth + counter) < teamMembers.length * 320) {
          setCounter(counter + 320);
        }
      }
    } else {
      const containerWidth = membersElement?.current?.offsetWidth / 250
      const canUse = containerWidth < teamMembers.length
      if (canUse && counter < 0) {
        if ((membersElement?.current?.offsetWidth + counter) < teamMembers.length * 250) {
          setCounter(counter + 250);
        }
      }
    }
  }


  return (
    <div className="our-team__container" id="ourTeam">
      <div className="our-team__title-container">
        <span className="our-team__title-container__text">Nuestro equipo</span>
        <div className="our-team__title-container__arrows">
          <MdKeyboardArrowLeft
            onClick={() => previousSlide(0)}
            className="our-team__title-container__arrow"
          />
          <MdKeyboardArrowRight
            onClick={() => nextSlide(0)}
            className="our-team__title-container__arrow"
          />
        </div>
      </div>
      <div
        className="our-team__members-container"
        ref={membersElement}
        style={{ transform: `translate(${counter}px)` }}
      >
        {teamMembers.map((item, i) => {
          return (
            <div className="our-team__members" key={i}>
              <img className="our-team__members__img" src={item.img} alt="" />
              <span className="our-team__members__name">{item.name}</span>
              <span className="our-team__members__description">
                {item.description}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurTeam;
